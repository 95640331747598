import React from 'react'
import { Checkbox } from 'shared/components/checkbox'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from 'shared/components/form/field-error-and-description'
import { FieldLabel } from 'shared/components/form/field-label'
import { PaymentMethodEnum, PaymentMethodTitle } from 'shared/enums/payment-method-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import BancontactStripeIcon from 'shared/icons/bancontact-stripe-icon'
import BoletoIcon from 'shared/icons/boleto-icon'
import CashOnDeliveryIcon from 'shared/icons/cash-on-delivery-icon'
import CreditCardIcon from 'shared/icons/creadit-card-icon'
import FlutterwaveIcon from 'shared/icons/flutterwave-icon'
import GocardlessIcon from 'shared/icons/gocardless-icon'
import IDealStripeIcon from 'shared/icons/ideal-stripe-icon'
import MollieIcon from 'shared/icons/mollie-icon'
import PaypalIcon from 'shared/icons/paypal-icon'
import PaystackIcon from 'shared/icons/paystack-icon'
import PixIcon from 'shared/icons/pix-icon'
import RazorpayIcon from 'shared/icons/razorpay-icon'
import XenditIcon from 'shared/icons/xendit-icon'

interface PaymentMethodsProps extends FieldErrorAndDescriptionProps {
  value?: PaymentMethodEnum[]
  onChange: (value?: PaymentMethodEnum[]) => void
  data?: PaymentMethodEnum[]
  isPreFetching?: boolean
  required?: boolean
}

const paymentMethodsIcons: Record<PaymentMethodEnum, JSX.Element> = {
  [PaymentMethodEnum.stripe_card]: <CreditCardIcon />,
  [PaymentMethodEnum.stripe_ideal]: <IDealStripeIcon />,
  [PaymentMethodEnum.stripe_bancontact]: <BancontactStripeIcon />,
  [PaymentMethodEnum.dlocal_card]: <CreditCardIcon />,
  [PaymentMethodEnum.dlocal_boleto]: <BoletoIcon />,
  [PaymentMethodEnum.dlocal_pix]: <PixIcon />,
  [PaymentMethodEnum.paypal_soap]: <PaypalIcon />,
  [PaymentMethodEnum.paypal_rest]: <PaypalIcon />,
  [PaymentMethodEnum.mercadopago_card]: <CreditCardIcon />,
  [PaymentMethodEnum.mercadopago_boleto]: <BoletoIcon />,
  [PaymentMethodEnum.mercadopago_pix]: <PixIcon />,
  [PaymentMethodEnum.mercadopago_br_card]: <CreditCardIcon />,
  [PaymentMethodEnum.mercadopago_br_boleto]: <BoletoIcon />,
  [PaymentMethodEnum.mercadopago_br_pix]: <PixIcon />,
  [PaymentMethodEnum.mercadopago_ar_card]: <CreditCardIcon />,
  [PaymentMethodEnum.mercadopago_co_card]: <CreditCardIcon />,
  [PaymentMethodEnum.mercadopago_mx_card]: <CreditCardIcon />,
  [PaymentMethodEnum.mercadopago_cl_card]: <CreditCardIcon />,
  [PaymentMethodEnum.razorpay]: <RazorpayIcon />,
  [PaymentMethodEnum.flutterwave]: <FlutterwaveIcon />,
  [PaymentMethodEnum.mollie]: <MollieIcon />,
  [PaymentMethodEnum.gocardless]: <GocardlessIcon />,
  [PaymentMethodEnum.cash_on_delivery]: <CashOnDeliveryIcon />,
  [PaymentMethodEnum.xendit_id_card]: <XenditIcon />,
  [PaymentMethodEnum.xendit_ph_card]: <XenditIcon />,
  [PaymentMethodEnum.paystack_gh_card]: <PaystackIcon />,
  [PaymentMethodEnum.paystack_ke_card]: <PaystackIcon />,
  [PaymentMethodEnum.paystack_ng_card]: <PaystackIcon />,
  [PaymentMethodEnum.paystack_za_card]: <PaystackIcon />,
  [PaymentMethodEnum.paystack_eg_card]: <PaystackIcon />,
  [PaymentMethodEnum.paystack_ci_card]: <PaystackIcon />,
  [PaymentMethodEnum.secure_and_pay]: <CreditCardIcon />,
}

const PaymentMethods = ({
  value,
  onChange,
  data,
  isPreFetching,
  error,
  required,
}: PaymentMethodsProps) => {
  const { t } = useLocoTranslation()
  return (
    <div
      className={`text-sm font-medium flex-col gap-1 ${
        data && data.length === 0 ? 'hidden' : 'flex'
      }`}
    >
      <FieldLabel
        label={t('dashboard.payment_methods.allowed_payment_methods')}
        required={required}
      />
      <ul className={'flex flex-wrap flex-row gap-5'}>
        {isPreFetching || !data || !value ? (
          <PaymentMethodsSkeleton />
        ) : (
          data.map(el => {
            //we don't support xendit ewallet and xendit direct debit yet, so we need to skip them here
            if (!paymentMethodsIcons[el]) {
              return
            }
            const isSelected = value.includes(el)
            const label = t(PaymentMethodTitle[el])
            return (
              <li key={el}>
                <Checkbox
                  value={isSelected}
                  label={
                    <div
                      className={`flex gap-1.5 items-center [&>svg]:w-[20px] [&>svg]:h-[20px] ${
                        isSelected && '[&>svg]:fill-blue [&>svg]:main-transition-colors'
                      }`}
                    >
                      {paymentMethodsIcons[el]}
                      {label}
                    </div>
                  }
                  onChange={() =>
                    isSelected
                      ? onChange(value.filter(method => method !== el))
                      : onChange([...value, el])
                  }
                />
              </li>
            )
          })
        )}
      </ul>
      <FieldErrorAndDescription error={error} />
    </div>
  )
}

const PaymentMethodsSkeleton = () => (
  <div className={'flex gap-7 animate-pulse'}>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
  </div>
)

export default PaymentMethods
