import React from 'react'

function IDealStripeIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="#CBD5E1"
      stroke="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.62919 4.03262V19.4089H14.3212C20.3975 19.4089 23.0326 15.9768 23.0326 11.1231C23.0326 6.47761 20.3975 2.87352 14.3212 2.87352H8.78829C8.14535 2.87352 7.62919 3.39874 7.62919 4.03262Z"
        stroke="none"
      />
      <path
        d="M2.73866 21.7997H13.5509C17.3649 21.7997 20.3397 20.8565 22.3619 19.0428C24.3859 17.2276 25.4412 14.5533 25.4412 11.1229C25.4412 9.1476 25.0571 6.48868 23.3725 4.32192C21.6842 2.15039 18.704 0.491309 13.5509 0.491309H2.73866C1.72355 0.491309 0.9 1.31485 0.9 2.32997V19.961C0.9 20.9762 1.72355 21.7997 2.73866 21.7997ZM1.67955 2.32997C1.67955 1.74225 2.15095 1.27086 2.73866 1.27086H13.5509C15.6357 1.27086 18.4163 1.59408 20.6727 2.98558C22.9212 4.37219 24.6617 6.82698 24.6617 11.1229C24.6617 14.3254 23.6728 16.7946 21.7996 18.4647C19.9246 20.1364 17.1491 21.0202 13.5509 21.0202H2.73866C2.15095 21.0202 1.67955 20.5488 1.67955 19.961V2.32997Z"
        strokeWidth="0.2"
      />
      <path
        d="M9.9854 9.04946C10.2208 9.04946 10.4382 9.08568 10.6464 9.15813C10.8547 9.23057 11.0268 9.34829 11.1807 9.49318C11.3256 9.64712 11.4433 9.83729 11.5339 10.0546C11.6154 10.281 11.6607 10.5436 11.6607 10.8515C11.6607 11.1232 11.6244 11.3677 11.5611 11.5941C11.4886 11.8204 11.389 12.0197 11.2532 12.1827C11.1173 12.3457 10.9453 12.4724 10.737 12.5721C10.5287 12.6626 10.2842 12.7169 10.0035 12.7169H8.41879V9.04041H9.9854V9.04946ZM9.93106 12.0468C10.0488 12.0468 10.1575 12.0287 10.2752 11.9925C10.3838 11.9563 10.4834 11.8929 10.5649 11.8023C10.6464 11.7118 10.7189 11.6031 10.7732 11.4582C10.8276 11.3133 10.8547 11.1503 10.8547 10.9421C10.8547 10.761 10.8366 10.5889 10.8004 10.444C10.7642 10.2991 10.7008 10.1633 10.6193 10.0637C10.5378 9.96407 10.4291 9.87351 10.2933 9.81918C10.1575 9.76485 9.99445 9.73768 9.79523 9.73768H9.21568V12.0559H9.93106V12.0468Z"
        fill="white"
        stroke={'none'}
      />
      <path
        d="M14.9314 9.04946V9.72862H12.9935V10.5165H14.7775V11.1413H12.9935V12.0378H14.9767V12.7169H12.1876V9.04041H14.9314V9.04946Z"
        fill="white"
        stroke={'none'}
      />
      <path
        d="M17.7008 9.04926L19.0773 12.7258H18.2351L17.9544 11.9108H16.5779L16.2882 12.7258H15.4732L16.8587 9.04926H17.7008ZM17.7461 11.3041L17.2843 9.95482H17.2752L16.7953 11.3041H17.7461Z"
        fill="white"
        stroke={'none'}
      />
      <path
        d="M20.3893 9.04926V12.0466H22.1823V12.7258H19.5834V9.04926H20.3893Z"
        fill="white"
        stroke={'none'}
      />
      <path
        d="M4.60354 12.5799C5.53877 12.5799 6.29693 11.8217 6.29693 10.8865C6.29693 9.95125 5.53877 9.1931 4.60354 9.1931C3.66832 9.1931 2.91016 9.95125 2.91016 10.8865C2.91016 11.8217 3.66832 12.5799 4.60354 12.5799Z"
        stroke="none"
      />
      <path
        d="M5.87874 19.4088C4.45703 19.4088 3.31603 18.2588 3.31603 16.8461V14.8449C3.31603 14.1385 3.88653 13.559 4.60192 13.559C5.30825 13.559 5.8878 14.1295 5.8878 14.8449V19.4088H5.87874Z"
        stroke="none"
      />
    </svg>
  )
}

export default IDealStripeIcon
