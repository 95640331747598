import React from 'react'

function GocardlessIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="56"
      height="59"
      viewBox="0 0 56 59"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 58.165C43.464 58.165 56 45.181 56 29.165C56 27.12 55.817 25.101 55.464 23.137H27.512L47.768 45.165C26.4 63.072 0.073 17.275 13.577 8.73C15.863 7.283 19.133 7.127 23.385 8.263L24.03 8.443C24.248 8.506 24.468 8.573 24.69 8.643L25.365 8.861L25.708 8.978L26.404 9.226C26.757 9.354 27.114 9.491 27.477 9.634L28.211 9.93L28.959 10.246C29.211 10.354 29.466 10.466 29.722 10.581L30.5 10.935L31.293 11.309L32.1 11.702L32.923 12.115L33.339 12.328L34.183 12.77L35.043 13.23L35.916 13.711L36.805 14.211L37.255 14.468L38.165 14.997L39.09 15.545C39.246 15.638 39.402 15.731 39.558 15.826L40.985 16.699L41.955 17.305L42.939 17.931L43.938 18.576L44.952 19.24L45.98 19.924L47.023 20.627L48.616 21.718L49.154 22.091L38.543 1.11C36.197 0.207001 33.228 0.038 30.418 0.00699997L28.95 0H28C12.536 0 0 13.149 0 29.165C0 45.181 12.536 58.165 28 58.165Z"
      />
    </svg>
  )
}

export default GocardlessIcon
