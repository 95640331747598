import { useState } from 'react'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { useCustomToasts } from 'shared/hooks/use-custom-toasts'

export const useCurrency = ({ currencyChangeMessage }: { currencyChangeMessage: string }) => {
  const [currentCurrency, setCurrentCurrency] = useState<CurrencyEnum>()
  const [toastShown, setToastShown] = useState(false)
  const { infoToast } = useCustomToasts()

  const showToast = () => {
    if (toastShown) return
    infoToast(currencyChangeMessage)
    setToastShown(true)
  }

  return { currentCurrency, setCurrentCurrency, showToast }
}
