import React from 'react'

function FlutterwaveIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M-0.000106812 4.07082C-0.000106812 2.90389 0.339278 1.90757 1.05149 1.19147L2.29012 2.43686C0.911525 3.81801 2.11547 8.11214 6.04069 12.0625C9.96591 16.0129 14.2392 17.2222 15.6165 15.8386L16.8552 17.084C14.5315 19.4203 9.23883 17.7627 4.80825 13.3067C1.73521 10.2156 -0.000106812 6.70857 -0.000106812 4.07082Z" />
      <path d="M7.81099 18.1573C6.65039 18.1573 5.65949 17.8161 4.94604 17.1L6.18467 15.8546C7.56079 17.2382 11.8316 16.0277 15.7605 12.0786C19.6894 8.1294 20.8909 3.83527 19.5148 2.45163L20.7534 1.20624C23.0783 3.5426 21.4285 8.86418 16.9967 13.319C13.9224 16.4138 10.4344 18.1573 7.81099 18.1573Z" />
      <path d="M17.4053 11.5363C16.6534 9.35934 15.1175 7.03294 13.08 4.98551C8.64939 0.529482 3.35672 -1.12939 1.03305 1.20822C0.868316 1.37511 1.01076 1.78733 1.35262 2.13106C1.69448 2.47479 2.10447 2.61677 2.26921 2.45113C3.64656 1.06749 7.91736 2.27802 11.8451 6.22842C13.703 8.09651 15.0878 10.1826 15.7554 12.1129C16.34 13.8054 16.2831 15.1704 15.6018 15.8553C15.4358 16.021 15.5795 16.4344 15.9201 16.7769C16.2608 17.1194 16.672 17.2639 16.838 17.097C18.0283 15.9002 18.2302 13.9225 17.4053 11.5363Z" />
      <path d="M20.751 1.20826C19.5582 0.00894456 17.5937 -0.194055 15.218 0.636625C13.0541 1.39134 10.7391 2.93687 8.70281 4.9843C4.27223 9.43909 2.62237 14.7607 4.94604 17.0983C5.11202 17.2639 5.522 17.1207 5.86387 16.7782C6.20573 16.4357 6.34817 16.0223 6.18219 15.8554C4.80236 14.4717 6.01002 10.1776 9.93896 6.22721C11.7969 4.35912 13.8716 2.96676 15.7915 2.29549C17.4748 1.70891 18.8323 1.76495 19.5148 2.44992C19.6795 2.61556 20.0908 2.47234 20.4314 2.12861C20.772 1.78488 20.9157 1.3739 20.751 1.20826Z" />
    </svg>
  )
}

export default FlutterwaveIcon
