import React from 'react'

function PayPalIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.67824 19.4354L9.29538 15.5267L9.3387 15.3101C9.37119 15.1261 9.46863 14.9528 9.6094 14.8229C9.75013 14.7038 9.94504 14.628 10.1291 14.628H10.6163C11.4284 14.628 12.1863 14.5414 12.8468 14.3682C13.5506 14.1841 14.1786 13.9026 14.7199 13.5236C15.283 13.123 15.7594 12.6033 16.1275 11.9753C16.5173 11.3148 16.8096 10.5244 16.9829 9.62575C17.1345 8.82452 17.167 8.10991 17.0695 7.50357C16.9612 6.85392 16.7122 6.31255 16.3333 5.86862C16.095 5.59794 15.8027 5.37056 15.4454 5.17567H15.4346V5.16485C15.5645 4.36362 15.5537 3.70314 15.4237 3.12928C15.2938 2.55543 15.0123 2.03572 14.59 1.54847C13.7022 0.54152 12.0889 0.0218048 9.8043 0.0218048H3.51355C3.30784 0.0218048 3.10211 0.0975972 2.9397 0.23835C2.7773 0.379114 2.66902 0.563184 2.63654 0.768897L0.00546765 17.3998C-0.016197 17.5514 0.0271323 17.703 0.124567 17.8221C0.222013 17.9412 0.373609 18.0062 0.525194 18.0062H4.43389V18.0278L4.1632 19.7278C4.14154 19.8577 4.18486 19.9984 4.27149 20.0959C4.35809 20.1933 4.48802 20.2583 4.61796 20.2583H7.89866C8.08273 20.2583 8.25597 20.1933 8.39673 20.0742C8.53748 19.9551 8.62411 19.7927 8.65659 19.6087L8.67825 19.4354"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4549 5.16492C15.4333 5.28402 15.4116 5.40313 15.3899 5.53305C14.5562 9.79905 11.7194 11.2716 8.09223 11.2716H6.24074C5.79683 11.2716 5.42869 11.5964 5.35291 12.0295L4.14023 19.7278C4.09691 20.0093 4.31346 20.2692 4.60582 20.2692H7.88651C8.27631 20.2692 8.60113 19.9877 8.66609 19.6087L8.69858 19.4463L9.31576 15.5376L9.35907 15.3211C9.42403 14.9421 9.74884 14.6606 10.1386 14.6606H10.6259C13.7983 14.6606 16.2886 13.3721 17.0141 9.64748C17.3172 8.08833 17.1656 6.78904 16.3536 5.87953C16.1046 5.57636 15.8014 5.34899 15.4549 5.16493"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5792 4.81794C14.4492 4.78546 14.3193 4.75298 14.1894 4.7205C14.0595 4.68801 13.9187 4.66636 13.7779 4.6447C13.2799 4.56891 12.7385 4.5256 12.1538 4.5256H7.21651C7.0974 4.5256 6.9783 4.55809 6.88086 4.6014C6.65348 4.70967 6.48024 4.92622 6.44776 5.18607L5.3975 11.8341L5.36501 12.029C5.42997 11.5959 5.80894 11.2711 6.25286 11.2711H8.10436C11.7316 11.2711 14.5683 9.79856 15.402 5.53255C15.4237 5.40262 15.4454 5.28352 15.467 5.16442C15.2613 5.05614 15.0339 4.95869 14.7849 4.87208C14.7091 4.85043 14.6441 4.8396 14.5792 4.81794"
        fill="#B3BCC7"
        className={
          'group-aria-checked:fill-blue-300 group-aria-checked:transition-colors group-aria-checked:easy-out group-aria-checked:duration-75'
        }
      />
    </svg>
  )
}

export default PayPalIcon
