import React from 'react'

function XenditIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="49"
      height="47"
      viewBox="0 0 49 47"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="#CBD5E1"
    >
      <path d="M26.5134 0.399902H13.6093L0.300049 23.4596L13.6093 46.5193L16.2422 41.9768L5.56587 23.4596L16.2422 4.97133H23.8805L26.5134 0.399902Z" />
      <path d="M17.4285 16.0526L20.0325 11.539L16.2423 4.97119L13.6094 9.48476L17.4285 16.0526Z" />
      <path d="M32.358 4.97119L34.9909 9.51369L16.2423 41.9766L13.6094 37.4341L32.358 4.97119Z" />
      <path d="M22.1155 46.5193L24.7195 41.9768H32.3578L43.0341 23.4596L32.3578 4.97133L34.9907 0.399902L48.2999 23.4596L34.9907 46.5193H22.1155Z" />
      <path d="M31.1139 30.7798L28.481 35.3223L32.358 41.9769L34.9909 37.4633L31.1139 30.7798Z" />
    </svg>
  )
}

export default XenditIcon
