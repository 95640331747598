export enum CurrencyEnum {
  ARGENTINE_PESO = 'ars',
  AUSTRALIAN_DOLLAR = 'aud',
  // BULGARIAN_LEV = 'bgn',
  BRAZILIAN_REAL = 'brl',
  CANADIAN_DOLLAR = 'cad',
  CENTRAL_AFRICAN_FRANC = 'xaf',
  CHILEAN_PESO = 'clp',
  CHINESE_YUAN = 'cny',
  COLOMBIAN_PESO = 'cop',
  CZECH_CROWN = 'czk',
  DANISH_KRONE = 'dkk',
  EGYPTIAN_POUND = 'egp',
  EURO = 'eur',
  GHANAIAN_CEDI = 'ghs',
  GUINEAN_FRANC = 'gnf',
  HONG_KONG_DOLLAR = 'hkd',
  HUNGARIAN_FORINT = 'huf',
  // ICELANDIC_KRONA = 'isk',
  INDIAN_RUPEE = 'inr',
  INDONESIAN_RUPIAH = 'idr',
  ISRAELI_NEW_SHEKEL = 'ils',
  JAPANESE_YEN = 'jpy',
  KENYAN_SHILLING = 'kes',
  MALAWIAN_KWACHA = 'mwk',
  MALAYSIAN_RINGGIT = 'myr',
  MEXICAN_PESO = 'mxn',
  MOROCCAN_DIRHAM = 'mad',
  NEW_ZEALAND_DOLLAR = 'nzd',
  NIGERIAN_NAIRA = 'ngn',
  NORWEGIAN_KRONE = 'nok',
  PHILIPPINE_PESO = 'php',
  POLISH_ZLOTY = 'pln',
  POUND_STERLING = 'gbp',
  ROMANIA_LEI = 'ron',
  RUSSIAN_RUBLE = 'rub',
  RWANDAN_FRANC = 'rwf',
  SAO_TOME_AND_PRINCIPE_DOBRA = 'std',
  SAUDI_RIYAL = 'sar',
  SIERRA_LEONEAN_LEONE = 'sll',
  SINGAPORE_DOLLAR = 'sgd',
  SOUTH_AFRICAN_RAND = 'zar',
  SRI_LANKAN_RUPEE = 'lkr',
  SWEDISH_KRONA = 'sek',
  SWISS_FRANC = 'chf',
  TANZANIAN_SHILLING = 'tzs',
  THAI_BAHT = 'thb',
  TURKISH_LIRA = 'try',
  UGANDAN_SHILLING = 'ugx',
  US_DOLLAR = 'usd',
  UNITED_ARAB_EMIRATES_DIRHAM = 'aed',
  WEST_AFRICAN_CFA_FRANC_BCEAO = 'xof',
  ZAMBIAN_KWACHA = 'zmw',
  SERBIAN_DINAR = 'rsd',
  UKRAINIAN_HRYVNIA = 'uah',
  ALBANIAN_LEK = 'all'
}

export enum CurrencyNameEnum {
  ars = 'currency.argentine_peso',
  aud = 'currency.australian_dollar',
  brl = 'currency.brazilian_real',
  bgn = 'currency.bulgarian_lev',
  cad = 'currency.canadian_dollar',
  xaf = 'currency.central_african_franc',
  cop = 'currency.colombian_peso',
  czk = 'currency.czech_koruna',
  cny = 'currency.chinese_yuan',
  clp = 'currency.chilean_peso',
  dkk = 'currency.danish_krone',
  egp = 'currency.egyptian_pound',
  eur = 'currency.euro',
  ghs = 'currency.ghanaian_cedi',
  gnf = 'currency.guinean_franc',
  hkd = 'currency.hong_kong_dollar',
  huf = 'currency.hungarian_forint',
  isk = 'currency.icelandic_krona',
  inr = 'currency.indian_rupee',
  idr = 'currency.indonesian_rupiah',
  ils = 'currency.israeli_shekel',
  jpy = 'currency.japanese_yen',
  kes = 'currency.kenyan_shilling',
  mwk = 'currency.malawian_kwacha',
  myr = 'currency.malaysian_ringgit',
  mxn = 'currency.mexican_peso',
  mad = 'currency.moroccan_dirham',
  nzd = 'currency.new_zealand_dollar',
  ngn = 'currency.nigerian_naira',
  nok = 'currency.norwegian_krone',
  php = 'currency.philippine_peso',
  pln = 'currency.polish_zloty',
  gbp = 'currency.pound_sterling',
  ron = 'currency.romanian_leu',
  rub = 'currency.russian_ruble',
  rwf = 'currency.rwandan_franc',
  std = 'currency.sao_tome_and_principe_dobra',
  sar = 'currency.saudi_riyal',
  sll = 'currency.sierra_leonean_leone',
  sgd = 'currency.singapore_dollar',
  zar = 'currency.south_african_rand',
  lkr = 'currency.sri_lankan_rupee',
  sek = 'currency.swedish_krona',
  chf = 'currency.swiss_franc',
  tzs = 'currency.tanzanian_shilling',
  thb = 'currency.thai_baht',
  try = 'currency.turkish_lira',
  ugx = 'currency.ugandan_shilling',
  usd = 'currency.us_dollar',
  aed = 'currency.uae_dirham',
  xof = 'currency.west_african_cfa_franc_bceao',
  zmw = 'currency.zambian_kwacha',
  rsd = 'currency.serbian_dinar',
  uah = 'currency.ukrainian_hryvnia',
  all = 'currency.albanian_lek'
}
