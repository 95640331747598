import { useMemo } from 'react'
import useSWR from 'swr'
import { UserDomainInterface } from '../types/user-domain-interface'
import { useApiWrapperWithErrorValidation } from './use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from './use-api-wrapper-with-error-validation/types'
import useUser from './use-user'

export default function useUserDomains(shouldFetch?: boolean) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    UserDomainInterface[]
  >({
    method: RequestMethodsEnum.get,
  })

  const { data: userDomains, mutate } = useSWR(() => {
    if (user && (shouldFetch || shouldFetch === undefined)) {
      return '/api/dashboard/customer/user-domains'
    }
    return null
  }, fetcher)

  const getDomainNameById = (id: number) => {
    if (userDomainsSelect) {
      const selected = userDomainsSelect.find(el => el.id === id)
      if (selected) return selected.caption
    }
  }
  const getDomainIdByName = (name?: string) => {
    if (userDomainsSelect) {
      const selected = userDomainsSelect.find(el => el.caption === name)
      if (selected) return selected.id
    }
  }

  const userDomainsSelect = useMemo(
    () =>
      Array.isArray(userDomains)
        ? userDomains.map(el => ({ caption: el.name, id: el.id }))
        : undefined,
    [userDomains],
  )

  return {
    userDomains,
    userDomainsSelect,
    mutate,
    getDomainNameById,
    getDomainIdByName,
  }
}
