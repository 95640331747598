import React from 'react'

function CashOnDeliveryIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22.3346 26.8581C22.5382 26.7636 22.7637 26.7709 22.9673 26.8727L24.4946 27.6363V24.8218H20.5601V27.68L22.3346 26.8581Z" />
      <path d="M37.2945 37.92C36.8218 37.6727 36.2909 37.6291 35.8109 37.8036L25.7164 41.4254C25.2218 41.7018 24.6473 41.8545 24.0436 41.8545H17.9564C17.5564 41.8545 17.2291 41.5273 17.2291 41.1273C17.2291 40.7273 17.5564 40.4 17.9564 40.4H24.0436C24.3855 40.4 24.72 40.3127 25.0036 40.1673C25.04 40.1454 25.0764 40.1236 25.1127 40.1018C25.5418 39.8327 25.8545 39.4109 25.9345 38.9236C26.0218 38.3854 25.8764 37.8691 25.5345 37.4691C25.1927 37.0691 24.6982 36.8436 24.1745 36.8436H19.6291C18.2982 36.8436 16.9673 36.3636 15.8836 35.4982C14.1091 34.08 10.4364 32.1891 5.58545 35.1491V43.9782C6.82909 43.9854 9.43272 44.2109 13.0545 45.6291C16.4509 46.96 20.0655 47.2654 23.5127 46.5163C27.0473 45.7454 32.3345 44.1818 37.3964 41.0763C37.7964 40.8291 38.0945 40.4145 38.2036 39.9345C38.3927 39.1345 38.0145 38.3054 37.2945 37.92Z" />
      <path d="M19.6293 35.3746H24.1748C25.1275 35.3746 26.022 35.7891 26.6402 36.5164C27.2584 37.2436 27.5202 38.2036 27.3675 39.1491C27.3602 39.2 27.3529 39.2436 27.3384 39.2946L32.3129 37.5127V26.0218C32.3129 25.36 31.7748 24.8291 31.1202 24.8291H25.942V28.8218C25.942 29.0764 25.8111 29.3091 25.6002 29.44C25.4839 29.5127 25.3529 29.5491 25.2148 29.5491C25.1057 29.5491 24.9893 29.52 24.8875 29.4691L22.6184 28.3346L20.1239 29.4836C19.8984 29.5855 19.6366 29.5709 19.4257 29.4327C19.2148 29.3018 19.0911 29.0691 19.0911 28.8218V24.8291H13.9129C13.2511 24.8291 12.7202 25.3673 12.7202 26.0218V32.3418C14.4657 32.7491 15.8548 33.6073 16.7857 34.3564C17.6293 35.0255 18.6111 35.3746 19.6293 35.3746Z" />
      <path d="M4.13102 32.7419L1.0183 32.6982L0.981934 45.8764L4.08739 45.9201L4.13102 45.8764V32.7419Z" />
      <path d="M25.782 10.9455C25.2147 10.611 24.5674 10.4219 23.8911 10.4219C21.8329 10.4219 20.1602 12.0946 20.1602 14.1528C20.1602 16.211 21.8329 17.8837 23.8911 17.8837C24.9529 17.8837 25.942 17.4255 26.6402 16.6619C26.0292 16.4873 25.4765 16.1382 25.0692 15.6437C24.0583 14.4219 24.182 12.5528 25.3456 11.3819L25.782 10.9455Z" />
      <path d="M32.3783 5.76718C32.4874 6.03627 32.4292 6.349 32.2183 6.55991L26.371 12.4072C25.731 13.0472 25.651 14.0581 26.1892 14.7126C26.4946 15.0835 26.9237 15.3017 27.3965 15.3235C27.8692 15.3526 28.3201 15.1708 28.6546 14.8363L33.5055 9.98536C33.7892 9.70173 34.2474 9.70173 34.531 9.98536C35.5201 10.9599 36.8292 11.4545 38.2183 11.469C39.5055 11.4472 40.7128 10.9526 41.6437 10.0726V1.09082H29.0401C27.8183 1.09082 26.6619 1.57082 25.7965 2.43627L22.9092 5.32355H31.7092C32.0001 5.31627 32.2692 5.49809 32.3783 5.76718Z" />
      <path d="M14.0222 15.6434C14.8456 15.6434 15.5131 14.9759 15.5131 14.1525C15.5131 13.3291 14.8456 12.6616 14.0222 12.6616C13.1988 12.6616 12.5312 13.3291 12.5312 14.1525C12.5312 14.9759 13.1988 15.6434 14.0222 15.6434Z" />
      <path d="M43.1055 1.09082H47.0255V9.64355H43.1055V1.09082Z" />
      <path d="M34.0584 11.4983L29.6875 15.8692C29.3384 16.2183 28.902 16.4655 28.4366 16.6183C27.5347 18.2765 25.7966 19.3383 23.8911 19.3383C21.0329 19.3383 18.7057 17.011 18.7057 14.1528C18.7057 11.2946 21.0329 8.96736 23.8911 8.96736C24.9457 8.96736 25.9711 9.29463 26.8293 9.891L29.9493 6.771H9.82565C9.55656 6.771 9.33838 6.98918 9.33838 7.25827V21.0328C9.33838 21.3019 9.55656 21.5201 9.82565 21.5201H37.9638C38.2329 21.5201 38.4511 21.3019 38.4511 21.0328V12.9019C38.3784 12.9019 38.3129 12.9165 38.2402 12.9165C36.7057 12.9165 35.2438 12.4437 34.0584 11.4983ZM14.022 17.0983C12.4002 17.0983 11.0766 15.7746 11.0766 14.1528C11.0766 12.531 12.4002 11.2074 14.022 11.2074C15.6438 11.2074 16.9675 12.531 16.9675 14.1528C16.9675 15.7746 15.6438 17.0983 14.022 17.0983Z" />
    </svg>
  )
}

export default CashOnDeliveryIcon
