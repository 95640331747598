import React from 'react'

function PaystackIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="49"
      height="47"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      fill="#CBD5E1"
      {...props}
    >
      <path d="M44.5947 49.5015H3.30047C1.91372 49.5015 0.732422 48.3202 0.732422 46.8821L0.732422 42.2082C0.732422 40.7701 1.91372 39.5888 3.30047 39.5888H44.5947C46.0328 39.5888 47.1628 40.7701 47.2141 42.2082V46.8307C47.2141 48.3202 46.0328 49.5015 44.5947 49.5015ZM44.5947 23.5642L3.30047 23.5642C2.63278 23.5642 1.96509 23.3074 1.50284 22.7937C0.989227 22.2801 0.732422 21.6638 0.732422 20.9448L0.732422 16.2709C0.732422 14.8328 1.91372 13.6515 3.30047 13.6515H44.5947C46.0328 13.6515 47.1628 14.7814 47.2141 16.2709V20.9448C47.1628 22.4342 46.0328 23.5642 44.5947 23.5642ZM26.567 10.6212H3.30047C2.63278 10.6212 1.96509 10.3644 1.50284 9.85078C1.04059 9.33717 0.732422 8.72084 0.732422 8.00178L0.732422 3.32793C0.732422 1.88982 1.91372 0.708518 3.30047 0.708518H26.5156C27.9537 0.708518 29.0837 1.88982 29.0837 3.27657V7.95042C29.135 9.49125 28.0051 10.6726 26.567 10.6212ZM47.2141 36.5585H3.30047C2.63278 36.5585 1.96509 36.3017 1.50284 35.7881C1.04059 35.2745 0.732422 34.6581 0.732422 33.9391L0.732422 29.2652C0.732422 27.8271 1.91372 26.6458 3.30047 26.6458L47.1628 26.6458C48.6009 26.6458 49.7308 27.8271 49.7308 29.2652V33.9391C49.7822 35.3772 48.6009 36.5071 47.2141 36.5585Z" />
    </svg>
  )
}

export default PaystackIcon
