export enum PaymentMethodEnum {
  stripe_card = 'stripe:card',
  stripe_ideal = 'stripe:ideal',
  stripe_bancontact = 'stripe:bancontact',
  dlocal_card = 'dlocal:card',
  dlocal_boleto = 'dlocal:boleto',
  dlocal_pix = 'dlocal:pix',
  paypal_soap = 'paypal-soap',
  paypal_rest = 'paypal-rest',
  mercadopago_br_card = 'mercadopago-br:card',
  mercadopago_br_pix = 'mercadopago-br:pix',
  mercadopago_br_boleto = 'mercadopago-br:boleto',
  mercadopago_card = 'mercadopago:card',
  mercadopago_pix = 'mercadopago:pix',
  mercadopago_boleto = 'mercadopago:boleto',
  mercadopago_co_card = 'mercadopago-co:card',
  mercadopago_ar_card = 'mercadopago-ar:card',
  mercadopago_mx_card = 'mercadopago-mx:card',
  mercadopago_cl_card = 'mercadopago-cl:card',
  razorpay = 'razorpay',
  flutterwave = 'flutterwave',
  mollie = 'mollie',
  gocardless = 'gocardless',
  cash_on_delivery = 'cash-on-delivery',
  xendit_id_card = 'xendit-id:card',
  xendit_ph_card = 'xendit-ph:card',
  paystack_gh_card = 'paystack-gh:card',
  paystack_ke_card = 'paystack-ke:card',
  paystack_ng_card = 'paystack-ng:card',
  paystack_za_card = 'paystack-za:card',
  paystack_eg_card = 'paystack-eg:card',
  paystack_ci_card = 'paystack-ci:card',
  secure_and_pay = 'secure-and-pay',
}

export const PaymentMethodTitle: Record<PaymentMethodEnum, string> = {
  [PaymentMethodEnum.stripe_card]: 'dashboard.payment.stripe_card',
  [PaymentMethodEnum.stripe_ideal]: 'dashboard.payment.stripe_ideal',
  [PaymentMethodEnum.stripe_bancontact]: 'dashboard.payment.stripe_bancotact',
  [PaymentMethodEnum.dlocal_card]: 'dashboard.payment.dlocal_card',
  [PaymentMethodEnum.dlocal_boleto]: 'dashboard.payment.dlocal_boleto',
  [PaymentMethodEnum.dlocal_pix]: 'dashboard.payment.dlocal_pix',
  [PaymentMethodEnum.paypal_soap]: 'dashboard.payment.paypal_soap',
  [PaymentMethodEnum.paypal_rest]: 'dashboard.payment.paypal_soap',
  [PaymentMethodEnum.mercadopago_card]: 'dashboard.payment.mercadopago_card',
  [PaymentMethodEnum.mercadopago_pix]: 'dashboard.payment.mercadopago_pix',
  [PaymentMethodEnum.mercadopago_boleto]: 'dashboard.payment.mercadopago_boleto',
  [PaymentMethodEnum.mercadopago_br_card]: 'dashboard.payment.mercadopago_card',
  [PaymentMethodEnum.mercadopago_br_pix]: 'dashboard.payment.mercadopago_pix',
  [PaymentMethodEnum.mercadopago_br_boleto]: 'dashboard.payment.mercadopago_boleto',
  [PaymentMethodEnum.mercadopago_co_card]: 'dashboard.payment.mercadopago_card',
  [PaymentMethodEnum.mercadopago_ar_card]: 'dashboard.payment.mercadopago_card',
  [PaymentMethodEnum.mercadopago_mx_card]: 'dashboard.payment.mercadopago_card',
  [PaymentMethodEnum.mercadopago_cl_card]: 'dashboard.payment.mercadopago_card',
  [PaymentMethodEnum.razorpay]: 'dashboard.payment.razorpay',
  [PaymentMethodEnum.flutterwave]: 'dashboard.payment.flutterwave',
  [PaymentMethodEnum.mollie]: 'dashboard.payment.mollie',
  [PaymentMethodEnum.gocardless]: 'dashboard.payment.gocardless',
  [PaymentMethodEnum.cash_on_delivery]: 'dashboard.payment.cash_on_delivery',
  [PaymentMethodEnum.xendit_id_card]: 'dashboard.payment.xendit_card',
  [PaymentMethodEnum.xendit_ph_card]: 'dashboard.payment.xendit_card',
  [PaymentMethodEnum.paystack_gh_card]: 'dashboard.payment.paystack_card',
  [PaymentMethodEnum.paystack_ke_card]: 'dashboard.payment.paystack_card',
  [PaymentMethodEnum.paystack_ng_card]: 'dashboard.payment.paystack_card',
  [PaymentMethodEnum.paystack_za_card]: 'dashboard.payment.paystack_card',
  [PaymentMethodEnum.paystack_eg_card]: 'dashboard.payment.paystack_card',
  [PaymentMethodEnum.paystack_ci_card]: 'dashboard.payment.paystack_card',
  [PaymentMethodEnum.secure_and_pay]: 'dashboard.payment.secure_and_pay',
}

export enum PaymentProcessorEnum {
  stripe = 'Stripe',
  'paypal-soap' = 'Paypal',
  'paypal-rest' = 'Paypal',
  dlocal = 'DLocal',
  mercadopago = 'Mercadopago',
  'mercadopago-br' = 'Mercadopago',
  'mercadopago-ar' = 'Mercadopago',
  'mercadopago-mx' = 'Mercadopago',
  'mercadopago-co' = 'Mercadopago',
  'mercadopago-cl' = 'Mercadopago',
  razorpay = 'Razorpay',
  flutterwave = 'Flutterwave',
  mollie = 'Mollie',
  gocardless = 'gocardless',
  'cash-on-delivery' = 'Сash on delivery',
  'xendit-id' = 'Xendit',
  'xendit-ph' = 'Xendit',
  'paystack-gh' = 'Paystack',
  'paystack-ke' = 'Paystack',
  'paystack-ng' = 'Paystack',
  'paystack-za' = 'Paystack',
  'paystack-eg' = 'Paystack',
  'paystack-ci' = 'Paystack',
  'secure-and-pay' = 'Secure and Pay',
}
