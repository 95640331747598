import React from 'react'

function RazorpayIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.19 9.44064L7.94 21.8806H0L1.61 15.7606L11.19 9.44064Z" />
      <path d="M18.3994 0.41037L12.7594 21.8804H8.88938L12.6994 7.34037L6.85938 11.1904L7.89937 7.36037L18.3994 0.41037Z" />
    </svg>
  )
}

export default RazorpayIcon
