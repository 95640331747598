import React from 'react'

function PixIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      version="1.1"
      fill="#CBD5E1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20px"
      viewBox="0 0 298 294"
      {...props}
    >
      <path
        opacity="1.000000"
        stroke="none"
        d="
M244.285446,208.610062
	C242.970535,209.638763 241.936996,211.008606 240.866455,211.038147
	C226.915070,211.423294 213.323441,212.234283 202.526245,199.743088
	C191.444809,186.923065 178.474030,175.756104 166.753845,163.461746
	C157.923599,154.198914 143.357864,154.168411 134.603729,163.190262
	C121.496552,176.698273 108.021919,189.849380 94.731537,203.180161
	C89.298790,208.629440 82.626717,210.348587 75.120163,211.342804
	C60.985691,213.214874 50.329533,208.886612 41.561268,197.525284
	C35.426071,189.575714 27.253853,183.221161 20.883156,175.425507
	C8.642242,160.446579 10.216591,137.694260 24.153507,123.132507
	C33.826618,113.025703 43.814171,103.215714 53.828175,93.444191
	C55.185833,92.119408 57.495472,91.183701 59.401691,91.115700
	C65.546120,90.896507 71.750221,90.741402 77.853561,91.346245
	C87.652054,92.317268 94.438049,98.733185 100.955849,105.375687
	C112.375610,117.013977 123.608986,128.844330 135.293442,140.210785
	C144.487930,149.155029 157.356705,148.646927 166.649048,139.429947
	C179.301895,126.879730 192.115875,114.471695 204.310608,101.486053
	C214.881454,90.229607 227.898621,89.801460 241.426010,92.080154
	C244.539246,92.604576 247.171005,96.638344 249.796814,99.283035
	C257.645477,107.188202 265.340271,115.245918 273.169800,123.170311
	C288.713440,138.902252 288.759369,163.937805 273.159729,179.690308
	C263.665131,189.277969 254.089081,198.784973 244.285446,208.610062
z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d="
M99.133804,254.866470
	C89.211319,245.070602 79.538452,235.525192 69.900787,226.014526
	C77.181381,224.578506 84.190994,224.060730 90.499619,221.683029
	C95.720055,219.715469 100.687065,216.152695 104.766792,212.251404
	C117.882652,199.709244 130.526199,186.673599 143.371429,173.847855
	C148.933350,168.294357 151.991394,168.267914 157.623703,173.866959
	C170.023743,186.193771 182.537323,198.413330 194.675293,210.994354
	C202.944809,219.565735 212.734009,224.177948 224.546219,224.728470
	C225.302353,224.763702 226.052200,224.934326 228.588257,225.296951
	C219.519348,234.040939 211.361176,241.787537 203.339722,249.673203
	C194.672058,258.194153 186.151550,266.864594 177.532349,275.435120
	C161.107605,291.767181 136.428574,291.868164 119.855301,275.622467
	C112.958298,268.861816 106.203102,261.956482 99.133804,254.866470
z"
      />
      <path
        opacity="1.000000"
        stroke="none"
        d="
M93.157654,81.812546
	C85.493309,79.981094 78.150978,78.351784 70.817352,76.724403
	C70.913933,76.604439 71.581711,75.602051 72.415405,74.764885
	C88.518112,58.595020 104.543739,42.346703 120.786789,26.318796
	C135.627319,11.674816 158.966370,10.582329 174.536713,24.857964
	C190.414688,39.415646 205.186234,55.179058 220.474991,70.381584
	C222.722427,72.616348 225.151276,74.668686 227.593521,76.894150
	C220.952347,78.432266 214.797546,79.087257 209.311203,81.357597
	C204.392059,83.393204 199.630020,86.613060 195.805130,90.342636
	C182.501434,103.314796 169.612640,116.713509 156.616074,129.998932
	C152.699371,134.002716 149.133774,134.139923 145.126892,130.125793
	C132.536896,117.513069 120.106400,104.735985 107.298668,92.348724
	C103.197273,88.381973 98.113815,85.430618 93.157654,81.812546
z"
      />
    </svg>
  )
}

export default PixIcon
