import React from 'react'

function BancontactStripeIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="24"
      height="10"
      viewBox="0 0 24 10"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.75347 9.9973C8.35951 9.9973 10.1625 7.57135 11.9656 5.14539H0V9.9973H4.75347Z" />
      <path d="M19.1778 0.293716C15.5717 0.293716 13.7687 2.71967 11.9657 5.14562H23.9312V0.293716H19.1778Z" />
    </svg>
  )
}

export default BancontactStripeIcon
