import React from 'react'

function CreditCardIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0035 0.218132H1.88928C0.84752 0.218132 0 1.06565 0 2.10741V12.1836C0 13.2254 0.84752 14.0729 1.88928 14.0729H17.0035C18.0453 14.0729 18.8928 13.2254 18.8928 12.1836V2.10741C18.8928 1.06565 18.0453 0.218132 17.0035 0.218132ZM15.744 4.62646H3.14879C2.80074 4.62646 2.51903 4.34476 2.51903 3.9967C2.51903 3.64865 2.80074 3.36694 3.14879 3.36694H15.744C16.0921 3.36694 16.3738 3.64865 16.3738 3.9967C16.3738 4.34476 16.0921 4.62646 15.744 4.62646ZM6.92737 10.9241H3.1488V7.14551H6.92737V10.9241Z"
      />
    </svg>
  )
}

export default CreditCardIcon
